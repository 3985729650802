// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:08+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            67dd8e775e84989be2e84919d05ea53c
//    Signature:      eyJpdiI6IkVacXVOK1drNjlHVEJtd3JOOStoeWc9PSIsInZhbHVlIjoiM1V6Y1MyakhlY254QVVQandmblRoRHAxbk1oYW94NzZ0dDlLMk5IeGdwK3NEUEl6cDNGcjVcLyt1MEJCYWpzZ3l4Vm1ReEJ0M1AyYjlKVVI3aG12MnIrd1wvbHZ1MU5wb1RidTY5aXBcL3ZUY2pjUFZ4UlM1b3pHc3VSYjFWdVwvOGtrSEVcL0xVdVhnRGxxRlBkV2FzZmpSSFhmWFVsQWJNRTJwbVFTN2tLSTVZNlwveVVFa3I5OXpQYmk1Mm1ETVVQQnNDbUVZSzhnQmRkbnhHbnBLdm9qT1FMTG1oelwvY0YrWGZKR1AxeEVvRWUxU0JYc3V4N01jZkFPWGFBa1dHaUp5elE1RjZiTjJ6Nkw4N2RiOEtmakJcL3NqTEI4dzlZSWs2bVVVNENBaEhBZTRaaTNQMzRDR0Z0VHd1bnM3bGVBWG9XTEhjWHdEa1psN1hWczJOZ0RGRytmXC9kSmd6cStMOEFocFJ6WklsaWJQRWR6RkdreTVhS1lSTmhsU3I5ZStHeGdOVFhvc3Nwd2dTZ1prSk96ZTVvQzhidz09IiwibWFjIjoiZmJhMDdkNzM5Yzc1NzljNjI4NmQ3YmQ2OGZiMDA4MmU5YjRjODY4ZTQ1ZDFkYmM0ZGRiYzk1NDY2NGM5OWU5YSJ9
class CartUpsell extends HTMLElement {
    constructor() {
      super();
      
      this.init();
      document.addEventListener('cartUpsell:init', function() {
        this.init();
      }.bind(this));
    };

    init() {
        const upsellAddToCartBtn = this.querySelectorAll('[js-quick-add-to-cart]');
        const selectVariant = this.querySelectorAll('[js-select-variant]');

        selectVariant.forEach( select => {
            select.addEventListener('change', (e) => {
                this.updateVariant(e);
            });
        });

        upsellAddToCartBtn.forEach( btn => {
            btn.addEventListener('click', (e) => {
                this.addToCart(e);
            });
        })
    }

    getSectionsToRender() {
        return [
          {
            id: 'main-cart-items',
            section: document.getElementById('main-cart-items').dataset.id,
            selector: '.js-contents',
          },
          {
            id: 'cart-icon-bubble',
            section: 'cart-icon-bubble',
            selector: '.shopify-section'
          },
          {
            id: 'cart-live-region-text',
            section: 'cart-live-region-text',
            selector: '.shopify-section'
          },
          {
            id: 'main-cart-footer',
            section: document.getElementById('main-cart-footer').dataset.id,
            selector: '.js-contents',
          },
          {
            id: 'cart-upsell',
            section: document.getElementById('cart-upsell').dataset.id,
            selector: '.upsell-items',
          }
        ];
    }

    updateVariant (evt) {
        const upsellCard = evt.target.closest("[js-upsell-card]");
        const atcBtn = upsellCard.querySelector("[js-quick-add-to-cart]");
        const btnLabel = upsellCard.querySelector("[js-btn-label]");

        const value = evt.target.value.split("/");
        const id = value[0];
        const available = value[1];
        const price = value[2];

        upsellCard.dataset.productId = id;

        if(available === 'true') {
            atcBtn.classList.remove("disabled");
            btnLabel.innerHTML = `Add ${price}`;
        }else {
            atcBtn.classList.add("disabled");
            btnLabel.innerHTML = `Sold out ${price}`
        }
    }

    addToCart (evt) {
        const upsellCard = evt.target.closest("[js-upsell-card]");
        const id = parseInt(upsellCard.dataset.productId);
        const quantity = 1;

        const data = {
            items: [
                {
                    id,
                    quantity
                }
            ],
            sections: this.getSectionsToRender().map((section) => section.section)
        };

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        upsellCard.querySelector("[js-quick-add-to-cart]").classList.add("loading");

        fetch(window.Shopify.routes.root + 'cart/add.js', config).then(response => {
            return response.json();
        }).then(parsedState => {
            this.getSectionsToRender().forEach((section => {
                const elementToReplace =
                    document.getElementById(section.id).querySelector(section.selector) || document.getElementById(section.id);
        
                elementToReplace.innerHTML =
                    this.getSectionInnerHTML(parsedState.sections[section.section], section.selector);
            }));
            document.dispatchEvent(new CustomEvent('cartUpsell:init'));
            upsellCard.querySelector("[js-quick-add-to-cart]").classList.remove("loading");
        })
    }

    getSectionInnerHTML(html, selector) {
        return new DOMParser()
          .parseFromString(html, 'text/html')
          .querySelector(selector).innerHTML;
    }
}
  
customElements.define('cart-upsell', CartUpsell);
  